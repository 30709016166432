<template>
    <div>
        <CModal
            :show.sync="openModal"
            :no-close-on-backdrop="true"
            :centered="false"
            title="Modal title 2"
            size="lg"
            color="dark"
            >
            <div v-html="preview_Html" style="overflow: auto;"></div>
            <template #header>
                <h6 class="modal-title">Previsualizar Html</h6>
                <CButtonClose @click="closeModal('Close_Modal_Preview_Boletin_Success')" class="text-white"/>
            </template>
            <template #footer>
                <CButton @click="closeModal('Close_Modal_Preview_Boletin_Success')" color="danger">Cerrar</CButton>
                <CButton @click="closeModal('Close_Modal_Preview_Boletin_Success')" color="success" style="display:none;">Accept</CButton>
            </template>
        </CModal>
    </div> 
</template>

<script>
export default {
  name: 'PreviewHtml',
  components: {
  },
  data () {
    return {
    }
  },
  props:{
    openModal: Boolean,
    preview_Html: String
  },
  computed:{
  },
  methods: {
    closeModal(type) {
      this.$emit("close-modal",type);
    },
  },
  mounted(){

  },
}
</script>

