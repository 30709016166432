<template>
  <CContainer>
    <CRow v-if="listaUsuario.length > 0">
    <CCol col="12">
      <CCard>
        <CCardBody>
           <CRow>
            <CCol col="4">
              <label type="text" class="col-sm-9 "><span>
                <strong>Cuentas</strong>
                </span></label>
                  <CSelect
                        label=""
                        :options="listaUsuario"
                        :value.sync="usuario_selected"
                        @change="filtroUsuariosChange"
                        >
                    </CSelect>
            </CCol>
           </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
    <CRow v-if="items_cargando.length > 0">
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <h3>Mis Envios Que se Estan Procesando Mailing</h3>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            striped
            :items="items_cargando"
            :fields="fields_cargando"
            :items-per-page="10"
            :pagination="{ doubleArrows: false, align: 'center'}"
            column-filter
            @page-change="pageChange_cargando"
            :loading="loading_tabla_1"
          >
          <template #estado="{item, index}">
                <td class="font-weight-bold text-warning">
                  {{item.estado}}
                </td>
            </template>
            <template #tipo_envio="{item, index}">
             <td>
             {{item.tipo_envio.replaceAll('_', ' ')}}
             </td>
           </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
    <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CRow class="justify-content-between">
            <CCol col="8"><h3>Mis Envios Programados Pendientes Por Enviar Mailing</h3></CCol>
            <CCol col="3"><CButton color="success" variant="outline" @click="actualizarTablas()" class="m-1"> Actualizar Tablas </CButton></CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="10"
            :pagination="{ doubleArrows: false, align: 'center'}"
            column-filter
            @page-change="pageChange"
            :loading="loading_tabla_2"
          >
          <template #tipo_envio="{item, index}">
             <td>
             {{item.tipo_envio.replaceAll('_', ' ')}}
             </td>
           </template>
           <template #mail_total="{item, index}">
             <td>
             {{$formatterNumeroTipoCreditos(item.mail_total)}}
             </td>
           </template>
           <template #pendientes="{item, index}">
              <td>
             {{$formatterNumeroTipoCreditos(item.pendientes)}}
             </td>
           </template>
            <template #accion="{item, index}">
               <td>
                 <tr v-if="!(item.estado).includes('No Enviado Por Spam')">
                  <CButton color="success" square size="sm" class="m-1" @click="openModalCorreoPreviewHtmlBoletin(item)">Ver Correo</CButton>
                  <CButton color="danger" variant="outline" square size="sm" @click="openModalcancelarEnvio(item)" class="m-1"> Cancelar Envio </CButton>
                  </tr>
               </td>
              </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <div>
    <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
    <CModal
        :show.sync="modal_cancelar_envio"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="sm"
        color="dark"
      >
        <CRow>
          <CCol col="12">
            Usted esta Apunto de Cancelar Los Envios Esta Seguro ? 
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">Cancelar Envios</h6>
          <CButtonClose @click="modal_cancelar_envio = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="modal_cancelar_envio = false" color="danger">Cancel</CButton>
          <CButton  color="success" @click="cancelarEnvio">Aceptar</CButton>
        </template>
      </CModal>
      <CModal
        :show.sync="modal_reenviar_envio"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="sm"
        color="dark"
      >
        <CRow>
          <CCol col="12">
            Usted esta Apunto de Reanudar Los Envios Esta Seguro ? 
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">Reanudar Envios</h6>
          <CButtonClose @click="modal_reenviar_envio = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="modal_reenviar_envio = false" color="danger">Cancel</CButton>
          <CButton  color="success" @click="reenviarMensajes()">Aceptar</CButton>
        </template>
      </CModal>
      <PreviewHtml :openModal="modal_preview_html_boletin" :preview_Html="previewHtmlModal" @close-modal="closeModal"/>
  </div>
  </CContainer>
  
</template>

<script>
import PreviewHtml from "../modals/PreviewHtml.vue";
export default {
  /*@keypress="isNumber($event)"
    @blur="formatCurrency($event,'blur')"
    @keyup="formatCurrency($event,'')" */
  name: 'CarguesProgramados',
  components: {
      PreviewHtml
  },
  data () {
    return {
      mensaje_success:'',
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError:0,
      mensaje_warning:'Debe Completar Los Datos Vacio',
      historial_select:'sms',
      items: [],
      items_filter: [],
      fields: [
        { key: 'proyecto',label:'Mensaje Primer registro',_classes: 'font-weight-bold'  },
        //{ key: 'mail_total',label:'Cantidad Mails Programados'},
        { key: 'pendientes',label:'Pendientes Por Enviar'},
        { key: 'fecha_sistema',label:'Fecha Cargue'},
        { key: 'fecha_prog_envio',label:'Fecha Programacion'},
        { key: 'tipo_envio',label:'Tipo Envio'},
        { key: 'estado',label:'Estado'},
        { key: 'accion',label:'Accion',
          _style: { width: '5%' },
          sorter: false,
          filter: false 
        }
      ],
      activePage: 1,
      modal_cancelar_envio:false,
      modal_reenviar_envio:false,
      form_cancelar_envio:{
        idproyecto:0
      },
      form_reenviar_envio:{
        idproyecto:0
      },
      items_cargando: [],
      fields_cargando: [
        { key: 'proyecto',label:'Mensaje Primer registro',_classes: 'font-weight-bold'  },
        { key: 'mail_total',label:'Cantidad Mails Programados'},
        { key: 'fecha_sistema',label:'Fecha Cargue'},
        { key: 'fecha_prog_envio',label:'Fecha Programacion'},
        { key: 'tipo_envio',label:'Tipo Envio'},
        { key: 'estado',label:'Estado'},
      ],
      activePage_cargando: 1,
      bandera_request:false,
      loading_tabla_1: false,
      loading_tabla_2: false,
      usuario_selected: 0,
      listaUsuario: [],
      modal_preview_html_boletin:false,
      previewHtmlModal:'',
    }
     
  },
  methods: {
    goBack() {
      this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/roles'})
    },
    obtenerHistorialEnvios(usuario_carga = 0) {
        if (this.bandera_request == false) {
          this.bandera_request = true;
          this.loading_tabla_2=true
          this.$store.dispatch('auth/loading_http','visible');
          this.$http.post(this.$httpUrlMail+'/mail/envio/programados/index',{
            usuario:usuario_carga
          },{ withCredentials: false }).then(response => {
            this.items = response.data;
            this.items.map((item,id) => {  
            if (id%2 == 0) {
              item['_classes'] = 'table-now'; 
            }
            return item;
            })
            this.bandera_request = false;
            this.loading_tabla_2=false
            //console.log(response);
          }).catch((e)=>{
            this.mostrarNotificacionError ++;
          }).finally(() =>{this.$store.dispatch('auth/loading_http','hidden');this.bandera_request = false;});
        }
    },
    obtenerHistorialEnviosProcesando(usuario_carga = 0) {
        this.loading_tabla_1=true
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.post(this.$httpUrlMail+'/mail/envio/procesando',{
          usuario:usuario_carga
        },{ withCredentials: false }).then(response => {
          this.items_cargando = response.data;
          this.items_cargando.map((item,id) => {  
            if (id%2 == 0) {
              item['_classes'] = 'table-now'; 
            }
            return item;
            })
          this.loading_tabla_1=false
        }).catch((e)=>{
          this.mostrarNotificacionError ++;
        }).finally(() =>{this.$store.dispatch('auth/loading_http','hidden');});
    },
    validator (val) {
      return val ? val.length >= 4 : false
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    pageChange_cargando (val) {
      this.$router.push({ query: { page: val }})
    },
    openModalcancelarEnvio(item){
      this.form_cancelar_envio.idproyecto  = item.idproyecto;
      this.modal_cancelar_envio=true
    },
    openModalreenviarMensajes(item){
      let saldoActual =  this.$store.state.auth.user.sms.saldo_actual;
      try {
        if (parseInt(saldoActual) > parseInt(item.precio_total)) {
        this.form_reenviar_envio.idproyecto = item.idproyecto;
        this.modal_reenviar_envio=true 
        }else{
          this.mostrarNotificacionAlerta ++
          this.mensaje_warning='Saldo Insuficiente, Para Poder Reanudar Los Envios debe Recargar Saldos Sms'
        }
      } catch (error) {
          this.mostrarNotificacionAlerta ++
          this.mensaje_warning='Saldo Insuficiente, Para Poder Reanudar Los Envios debe Recargar Saldos Sms'
      }
    },
    cancelarEnvio(evt){
      evt = (evt) ? evt : window.event;
      evt.target.disabled = true;
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.post(this.$httpUrlMail+'/mail/envio/programados',{
        accion_envio:'cancelar',
        idproyecto:this.form_cancelar_envio.idproyecto
      },{ withCredentials: false }).then(response => {
        if (response.data== "OK") {
          this.modal_cancelar_envio=false
          this.mensaje_success = 'Envio Cancelado Correctamente'
          this.mostrarNotificacion ++
          this.obtenerHistorialEnvios(this.usuario_selected);
        }else{
           this.mostrarNotificacionError ++;
            this.modal_cancelar_envio=false
        }
        evt.target.disabled = false;
      }).catch((e)=>{
        evt.target.disabled = false;
        this.mostrarNotificacionError ++;
      }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    reenviarMensajes(evt){
      evt = (evt) ? evt : window.event;
      evt.target.disabled = true;
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.post(this.$httpUrlMail+'/mail/envio/programados',{
        accion_envio:'reanudar',
        idproyecto:this.form_reenviar_envio.idproyecto
      },{ withCredentials: false }).then(response => {
        if (response.data== "OK") {
          this.modal_reenviar_envio=false
          this.mensaje_success = 'Envio Reanudado Correctamente'
          this.mostrarNotificacion ++
          this.obtenerHistorialEnvios(this.usuario_selected);
        }else if (response.data== "Sin Saldo"){
          this.mostrarNotificacionAlerta ++
          this.mensaje_warning='Saldo Insuficiente, Para Poder Reanudar Los Envios debe Recargar Saldos Sms'
        }else{
          this.mostrarNotificacionError ++;
          this.modal_reenviar_envio=false
        }
        evt.target.disabled = false;
      }).catch((e)=>{
        evt.target.disabled = false;
        this.mostrarNotificacionError ++;
      }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    descargarNumerosErrados(item){
        //console.log(item);
        let nombre = item.archivo_errado; 
        //var url = this.$apiAdress+`/storage/csv/${nombre}`
        var url = this.$apiAdress+`/reporte/descargar/general/csv|${nombre}`
        window.open(url, '_blank');
        /*this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/envio/reportes/errados/'+item.idproyecto,{ withCredentials: false }).then(response => {
          if (response.data== "Error") {
            this.mostrarNotificacionAlerta ++
            this.mensaje_warning='No Fue Posible Descargar El Reporte De Los Numeros Errados'
          }else{
             let nombre = response.data; 
             var url = this.$apiAdress+`/csv/${nombre}`
             window.open(url, '_blank');
          }
        }).catch((e)=>{
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));*/
    },
    descargarReporteSpam(item){
        let nombre = item.archivo_spam; 
        //var url = this.$apiAdress+`/storage/csv/${nombre}`
        var url = this.$apiAdress+`/reporte/descargar/general/reportes|${nombre}.zip`
        window.open(url, '_blank');
    },
    actualizarTablas(){
        this.items = [];
        let usuario = this.usuario_selected == 0 ? 0 :this.usuario_selected;
        this.obtenerHistorialEnvios(usuario);
        this.obtenerHistorialEnviosProcesando(usuario);
    },
    filtroUsuariosChange(){
        this.obtenerHistorialEnvios(this.usuario_selected);
        this.obtenerHistorialEnviosProcesando(this.usuario_selected);
    },
    obtenerUsuarios() {
          this.$http.get('/sms/envio/usuarios').then(response => {
            this.listaUsuario = response.data;
            let data = response.data;
            if (data.length > 0) {
              this.desahabilitarModoEnvioSubCuenta = false;
              this.listaUsuario = []
              this.usuario_selected = this.$store.state.auth.user.id;
              for (const iterator of data) {
                  this.listaUsuario.push({value:iterator.id,label:iterator.name+` (${iterator.padre})`})
              }
            }else{
              this.listaUsuario = []
              this.usuario_selected = 0
            }
          }).catch((e)=>{
          })
    },
     openModalCorreoPreviewHtmlBoletin(item){
        this.modal_preview_html_boletin = true;
        this.previewHtmlModal = item.mensaje;
      },
      closeModal(key,message = ""){
        switch (key) {
          case 'Close_Modal_Preview_Boletin_Success':
            this.modal_preview_html_boletin = false;
            break;
        }
      },
  },
  mounted () {
      setTimeout(() => {
        this.obtenerHistorialEnvios();
        this.obtenerHistorialEnviosProcesando();
        this.obtenerUsuarios();
      },300)
  },
}
</script>
